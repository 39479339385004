import React from "react";
import styled from "styled-components";
import Logo from "../../assets/logo.png";
import Image from "../UI/Image";
import { HomeAlt as DashboardIcon } from "styled-icons/boxicons-regular/HomeAlt";
import { Payment as PaymentsIcon } from "styled-icons/material/Payment";
import { People as VisitorsIcon } from "styled-icons/material/People";
import { PowerSettingsNew as LogoutIcon } from "styled-icons/material/PowerSettingsNew";
import { ThumbsUp as SalesIcon } from "styled-icons/fa-solid/ThumbsUp";
import UserInfo from "../UserInfo";
import NavLink from "./NavLink";

const AdminSidebar = () => {
  return (
    <SidebarContainer>
      <LogoContainer>
        <Image src={Logo} alt="Logo" />
      </LogoContainer>
      <MenuContainer>
        <NavLink to="/admin">
          <ItemIcon>
            <DashboardIcon size={18} />
          </ItemIcon>
          <ItemLink>Dashboard</ItemLink>
        </NavLink>

        <NavLink to="/admin/users">
          <ItemIcon>
            <VisitorsIcon size={18} />
          </ItemIcon>
          <ItemLink>Users</ItemLink>
        </NavLink>

        <NavLink to="/admin/visits">
          <ItemIcon>
            <VisitorsIcon size={18} />
          </ItemIcon>
          <ItemLink>Visits</ItemLink>
        </NavLink>

        <NavLink to="/admin/sales">
          <ItemIcon>
            <SalesIcon size={18} />
          </ItemIcon>
          <ItemLink>Sales</ItemLink>
        </NavLink>

        <NavLink to="/admin/payments">
          <ItemIcon>
            <PaymentsIcon size={18} />
          </ItemIcon>
          <ItemLink>Payments</ItemLink>
        </NavLink>

        <NavLink to="/logout">
          <ItemIcon>
            <LogoutIcon size={18} />
          </ItemIcon>
          <ItemLink>Logout</ItemLink>
        </NavLink>
      </MenuContainer>
      <BottomContainer>
        <UserContainer>
          <UserInfo />
        </UserContainer>
      </BottomContainer>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  position: relative;
  background: #001427;
  min-height: 100vh;
  color: white;
  width: 100%;
  height: 100%;
`;

const LogoContainer = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 32px;
  text-align: center;
`;

const MenuContainer = styled.div`
  text-align: left;
`;

const ItemIcon = styled.div`
  margin-right: 10px;
`;

const ItemLink = styled.div``;

const BottomContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  top: 88vh;
  left: 0;
  width: 100%;
`;

const UserContainer = styled.div`
  flex: 0 0 100%;
  margin-bottom: 10px;
`;

export default AdminSidebar;
