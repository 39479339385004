import React from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "react-apollo-hooks";
import ViewLayout from "../../layouts/ViewLayout";
import Loader from "../../components/Loader";
import GqlError from "../../components/GqlError";
import EditUserForm from "../../components/EditUserForm";
import { removeNullProps } from "../../lib/util";
import { useDispatch } from "react-redux";
import { showNotification } from "../../reducers/notification";
import { navigate } from "@reach/router";

const AdminUserPage = props => {
  const dispatch = useDispatch();
  const { data, error, loading } = useQuery(GET_USER, {
    variables: {
      id: Number(props.userId)
    }
  });
  const [updateUser] = useMutation(UPDATE_USER);

  if (loading) return <Loader />;
  if (error) return <GqlError error={error} />;

  const user = data.users.nodes[0];

  const handleUserSubmit = async formData => {
    try {
      const userUpdatedData = removeNullProps(formData);
      const { data } = await updateUser({
        variables: {
          input: userUpdatedData
        }
      });
      console.log("data", data);
      dispatch(showNotification("success", "User has been updated!"));
      navigate("/admin/users");
    } catch (e) {
      console.log(JSON.stringify(e));
      // dispatch(showNotification("error", e.graphQLErrors[0].message));
    }
  };

  return (
    <ViewLayout title={`Edit User ${props.userId}`}>
      <EditUserForm userData={user} onSubmit={handleUserSubmit} />
    </ViewLayout>
  );
};

const GET_USER = gql`
  query GET_USERS($id: Int) {
    users(filter: { id_eq: $id }) {
      nodes {
        id
        email
        companyName
        firstName
        lastName
        role
        country
        state
        city
        addressLine1
        addressLine2
        zip
        paymentMethod
        wireAccount
        paypalEmail
        commisionPercent
        minimumPayout
        promoMethod
        status
        createdAt
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      companyName
      firstName
      lastName
      role
      country
      state
      city
      addressLine1
      addressLine2
      zip
      paymentMethod
      wireAccount
      paypalEmail
      commisionPercent
      minimumPayout
      promoMethod
      status
      createdAt
    }
  }
`;

export default AdminUserPage;
