import styled from "styled-components";
import { useMarginProps } from "../../../style/mix";

const Label = styled.label`
  font-weight: 600;
  color: #444444;
  margin-bottom: 5px;

  ${useMarginProps}
`;

export default Label;
