import React from "react";
import styled from "styled-components";

const GqlError = ({ error }) => {
  console.error("error", error);
  return (
    <ErrorContainer>
      {error.graphQLErrors.map((errorItem, i) => (
        <ErrorItem key={i}>{errorItem.message}</ErrorItem>
      ))}

      {JSON.stringify(error)}
    </ErrorContainer>
  );
};

const ErrorContainer = styled.ul`
  font-weight: 700;
`;

const ErrorItem = styled.li`
  margin-bottom: 10px;
`;

export default GqlError;
