import React from "react";
import Button from "../UI/Button";
import { apolloClient } from "../..";
import { json2csv } from "json-2-csv";
import Loader from "../Loader";
import { gql } from "apollo-boost";
import { downloadCSV } from "../../lib/util";

export const VisitorExportButton = () => {
  const [isExporting, setIsExporting] = React.useState(false);

  const handleUserExport = async () => {
    try {
      setIsExporting(true);
      await processAndExportData();
    } catch (error) {
      console.error(error);
    } finally {
      setIsExporting(false);
    }
  };

  if (isExporting) return <Loader />;

  return <Button onClick={handleUserExport}> Export Visits </Button>;
};

const processAndExportData = async () => {
  try {
    const { data } = await apolloClient.query({
      query: GET_VISITORS,
      variables: {
        filter: {
          limit: 50000,
          orderByDesc: "id",
        },
      },
    });

    //Export the to csv file that can be downloaded
    const csvParser = (err, csv) => {
      if (err) {
        throw err;
      }
      downloadCSV(csv, "visitors.csv");
    };

    json2csv(data.me.visitors.nodes, csvParser, {
      keys: [
        "id",
        "referrerUrl",
        "destinationUrl",
        "ip",
        "userAgent",
        "createdAt",
      ],
    });
  } catch (error) {
    console.error(error);
  }
};

const GET_VISITORS = gql`
  query GET_VISITORS($filter: VisitorFilter) {
    me {
      id
      visitors(filter: $filter) {
        countBeforeFilter
        countAfterFilter
        nodes {
          id
          referrerUrl
          destinationUrl
          ip
          userAgent
          createdAt
        }
      }
    }
  }
`;
