import React from "react";
import Table from "./Table";
import Pagination from "./Pagination";
import TableSortLabel from "./TableSortLabel";
import Loader from "../Loader";
import { useQuery } from "react-apollo-hooks";
import GqlError from "../GqlError";
import styled from "styled-components";

const GQLDataTable = ({
  query,
  children,
  tableHeader,
  defaultSortCol,
  defaultSortDir,
  defaultRowsPerPage,
  rowsDataSelector,
  totalLengthSelector,
}) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    defaultRowsPerPage ? defaultRowsPerPage : 15
  );
  const [sortByColSelector, setSortByColSelector] = React.useState(
    defaultSortCol ? defaultSortCol : 0
  );
  const [sortDirection, setSortDirection] = React.useState(
    defaultSortDir ? defaultSortDir : "asc"
  );

  const queryOptions = {
    variables: {
      filter: {
        orderByAsc:
          sortDirection === "asc" && sortByColSelector
            ? sortByColSelector
            : null,
        orderByDesc:
          sortDirection === "desc" && sortByColSelector
            ? sortByColSelector
            : null,
        limit: Number(rowsPerPage),
        offset: rowsPerPage * currentPage,
      },
    },
  };

  const { data, error, loading, refetch } = useQuery(query, queryOptions);
  // const rawStateData = React.useState(null)

  const handleRowsPerPageChange = (newRowsPerPage) => {
    if (newRowsPerPage < 0) return;
    setRowsPerPage(newRowsPerPage);
  };

  const handleChangeSort = (colSelector) => {
    if (sortByColSelector === colSelector) {
      sortDirection === "asc"
        ? setSortDirection("desc")
        : setSortDirection("asc");
    } else {
      setSortByColSelector(colSelector);
      setSortDirection("asc");
    }
  };

  if (loading) return <Loader />;
  if (error) return <GqlError error={error} />;

  const rowData = rowsDataSelector(data);
  const tableApi = {
    refetch,
  };
  const itemsLength = totalLengthSelector(data);

  return (
    <>
      <Table align="left">
        <thead>
          <tr>
            {tableHeader.map((col, i) => (
              <th key={i}>
                <TableSortLabel
                  sortable={col.sortable ? true : false}
                  active={col.selector === sortByColSelector}
                  direction={sortDirection}
                  onClick={() => handleChangeSort(col.selector)}
                >
                  {col.label}
                </TableSortLabel>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>{children(rowData, tableApi)}</tbody>
      </Table>
      <PaginationContainer>
        <Pagination
          itemsLength={itemsLength}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          onChangePage={setCurrentPage}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </PaginationContainer>
    </>
  );
};

const PaginationContainer = styled.div`
  padding: 10px;
  width: 100%;
`;

export default GQLDataTable;
