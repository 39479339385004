import React from "react";
import Modal from "styled-react-modal";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { closeLinkModal } from "../../reducers/common";
import Textfield from "../UI/Form/Textfield";
import Button from "../UI/Button";
import * as Yup from "yup";
import useForm from "../../lib/useForm";
import FormHelperText from "../UI/Form/FormHelperText";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";

const formInitialData = {
  link: ""
};

const validationSchema = Yup.object({
  link: Yup.string()
    .required("Link is required")
    .url("Not a valid URL. Check if it starts with http://")
    .matches(/(autoblow.com)/, "Link must point to autoblow.com")
});

const CreateLinkModal = () => {
  const isOpen = useSelector(state => state.common.linkModalOpen);
  const [formField, formApi] = useForm(formInitialData, validationSchema);
  const dispatch = useDispatch();
  const { data, error, loading } = useQuery(GET_USER_ID);
  const [generatedLink, setGeneratedLink] = React.useState(null);

  if (loading) return null;
  if (error) return null;

  const handleClose = () => dispatch(closeLinkModal());

  const handleCreateLink = () => {
    const formData = formApi.getAllValues();
    const link = `${formData.link}?a_id=${data.me.id}`;
    setGeneratedLink(link);
    console.log(link);
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={handleClose}
      onEscapeKeydown={handleClose}
    >
      <HeaderContainer>Create Affiliate Link</HeaderContainer>
      <BodyContainer>
        To create an affiliate link paste any URL you'd like to link to from
        Autoblow.com website below. You will be able to copy/paste a version of
        this URL with your affiliate ID added.
        <FormContainer>
          <InputContainer>
            <Textfield {...formField.link} />
          </InputContainer>
          <ButtonContainer>
            <Button onClick={handleCreateLink} disabled={!formApi.isValid}>
              Create Link
            </Button>
          </ButtonContainer>
          <FormHelperText error>
            {formApi.getErrorMessage("link")}
          </FormHelperText>
        </FormContainer>
        {generatedLink && (
          <LinkContainer>
            Your affiliate link has been generated:
            <CodeBlock> {generatedLink} </CodeBlock>
          </LinkContainer>
        )}
      </BodyContainer>
    </StyledModal>
  );
};

export default CreateLinkModal;

const GET_USER_ID = gql`
  query GET_USER_ID {
    me {
      id
    }
  }
`;

const StyledModal = Modal.styled`
  width: 45rem;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: white;
`;

const HeaderContainer = styled.div`
  width: 100%;
  padding: 10px;
  background: #fafafb;
  border-bottom: 1px solid #e8e8e8;
  font-size: 20px;
  font-weight: 800;
`;

const BodyContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  padding: 10px;
`;

const FormContainer = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  flex: 0 0 70%;
`;

const ButtonContainer = styled.div`
  flex: 0 0 25%;
`;

const LinkContainer = styled.div`
  margin-top: 30px;
  font-weight: 700;
  font-size: 18px;
`;

const CodeBlock = styled.pre`
  background: #f1f1f1;
  padding: 10px;
`;
