import { css } from "styled-components";

const useMarginProps = css`
    ${props =>
      props.marginBottom !== undefined &&
      css`
        margin-bottom: ${props.marginBottom}px;
      `}

    ${props =>
      props.marginTop !== undefined &&
      css`
        margin-top: ${props.marginTop}px;
      `}

    ${props =>
      props.marginLeft !== undefined &&
      css`
        margin-left: ${props.marginLeft}px;
      `}

    ${props =>
      props.marginRight !== undefined &&
      css`
        margin-right: ${props.marginRight}px;
      `}
`;

const usePaddingProps = css`
    ${props =>
      props.paddingBottom !== undefined &&
      css`
        padding-bottom: ${props.paddingBottom}px;
      `}

    ${props =>
      props.paddingTop !== undefined &&
      css`
        padding-top: ${props.paddingTop}px;
      `}

    ${props =>
      props.paddingLeft !== undefined &&
      css`
        padding-left: ${props.paddingLeft}px;
      `}

    ${props =>
      props.paddingRight !== undefined &&
      css`
        padding-right: ${props.paddingRight}px;
      `}
`;

export { useMarginProps, usePaddingProps };
