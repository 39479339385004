import { useApolloClient } from "react-apollo-hooks";
import { navigate } from "@reach/router";
import { useDispatch } from "react-redux";
import { removeToken } from "../reducers/user";

const LogoutPage = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  dispatch(removeToken());
  client.resetStore();
  navigate("/");
  return null;
};

export default LogoutPage;
