import React from "react";
import { Chart } from "frappe-charts/dist/frappe-charts.min.esm";

const SalesChart = ({ groupedCommisions }) => {
  React.useEffect(() => {
    const data = {
      labels: groupedCommisions.map((record) => record.date),
      datasets: [
        {
          name: "Commisions USD",
          values: groupedCommisions.map((record) => record.total),
          chartType: "bar",
        },
      ],
    };
    new Chart("#salesChart", {
      data: data,
      type: "bar", // or 'bar', 'line', 'scatter', 'pie', 'percentage'
      height: 250,
      colors: ["#36880a"],
      barOptions: {
        spaceRatio: 0.2,
      },
      valuesOverPoints: 1, // default: 0
    });
  }, [groupedCommisions]);
  return <div id="salesChart" />;
};

export default SalesChart;
