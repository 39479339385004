import styled from "styled-components";
import { css } from "styled-components";

const Badge = styled.span`
  border-radius: 10px;
  padding: 5px;
  background-color: #5432ed;
  color: white;
  font-weight: 600;
  font-size: 14px;

  ${props =>
    props.info &&
    css`
      background-color: #209cee;
    `}

  ${props =>
    props.success &&
    css`
      background-color: #23d160;
    `}
    
  ${props =>
    props.warning &&
    css`
      background-color: #ffdd57;
      color: black;
    `}

  ${props =>
    props.danger &&
    css`
      background-color: #ff3860;
    `}
`;

export default Badge;
