import React from "react";
import styled from "styled-components";
import { UserCircle as UserIcon } from "styled-icons/boxicons-solid/UserCircle";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";

const UserInfo = () => {
  const { data, error, loading } = useQuery(GET_USER_NAME);
  if (loading) return null;
  if (error) return null;

  return (
    <UserContainer>
      <UserIconContainer>
        <UserIcon size={45} />
      </UserIconContainer>
      <UserDetailsContainer>
        {data.me.firstName} <br /> {data.me.role}
      </UserDetailsContainer>
    </UserContainer>
  );
};

const GET_USER_NAME = gql`
  query GET_USER_NAME {
    me {
      id
      firstName
      role
    }
  }
`;

const UserContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
`;

const UserIconContainer = styled.div`
  flex: 0 0 40%;
  text-align: right;
  margin-right: 10px;
`;

const UserDetailsContainer = styled.div`
  text-align: left;
  font-weight: 700;
  flex: 0 0 60%;
`;

export default UserInfo;
