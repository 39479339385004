import React from "react";
import { SortDown as SortDownIcon } from "styled-icons/boxicons-regular/SortDown";
import { SortUp as SortUpIcon } from "styled-icons/boxicons-regular/SortUp";
import styled from "styled-components";

const TableSortLabel = ({ active, direction, sortable, onClick, children }) => {
  if (active)
    return (
      <TableSortLabelContainer
        onClick={sortable ? onClick : null}
        sortable={sortable}
      >
        {children}
        {direction === "asc" ? (
          <SortDownIcon size={18} />
        ) : (
          <SortUpIcon size={18} />
        )}
      </TableSortLabelContainer>
    );

  return (
    <TableSortLabelContainer
      onClick={sortable ? onClick : null}
      sortable={sortable}
    >
      {children}
    </TableSortLabelContainer>
  );
};

const TableSortLabelContainer = styled.div`
  cursor: ${props => (props.sortable ? "pointer" : "auto")};
`;

export default TableSortLabel;
