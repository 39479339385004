import React from "react";
import Button from "../UI/Button";
import { apolloClient } from "../..";
import { json2csv } from "json-2-csv";
import Loader from "../Loader";
import { gql } from "apollo-boost";
import { downloadCSV } from "../../lib/util";

export const AdminUserExportButton = () => {
  const [isExporting, setIsExporting] = React.useState(false);

  const handleUserExport = async () => {
    try {
      setIsExporting(true);
      await processAndExportData();
    } catch (error) {
      console.error(error);
    } finally {
      setIsExporting(false);
    }
  };

  if (isExporting) return <Loader />;

  return <Button onClick={handleUserExport}> Export Users </Button>;
};

const processAndExportData = async (query) => {
  try {
    const data = await apolloClient.query({
      query: GET_USERS,
      variables: {
        filter: {
          limit: 50000,
          orderByDesc: "id",
        },
      },
    });

    //Export the to csv file that can be downloaded
    const csvParser = (err, csv) => {
      if (err) {
        throw err;
      }
      downloadCSV(csv, "users.csv");
    };

    json2csv(data.data.users.nodes, csvParser, {
      keys: [
        "id",
        "email",
        "role",
        "firstName",
        "lastName",
        "status",
        "createdAt",
      ],
    });
  } catch (error) {
    console.error(error);
  }
};

const GET_USERS = gql`
  query GET_USERS($filter: UserFilter) {
    users(filter: $filter) {
      countAfterFilter
      countBeforeFilter
      nodes {
        id
        email
        role
        firstName
        lastName
        status
        createdAt
      }
    }
  }
`;
