import React from "react";
import ViewLayout from "../layouts/ViewLayout";
import gql from "graphql-tag";
import GQLDataTable from "../components/GQLDataTable";
import { getDateFromStamp } from "../lib/util";
import TrimmedTd from "../components/GQLDataTable/TrimmedTd";
import { VisitorExportButton } from "../components/CSVExportButtons/VisitorExportButton";

const VisitorsPage = () => {
  const tableHeader = [
    { label: "Id", selector: "id", sortable: true },
    { label: "Referrer", selector: "referrerUrl", sortable: true },
    { label: "Destination", selector: "destinationUrl", sortable: true },
    { label: "Ip", selector: "ip" },
    { label: "User Agent", selector: "userAgent" },
    { label: "Created At", selector: "createdAt", sortable: true },
  ];

  return (
    <ViewLayout title="Referred Visits" ActionsComponent={VisitorExportButton}>
      <GQLDataTable
        query={GET_VISITORS}
        tableHeader={tableHeader}
        rowsDataSelector={(data) => data.me.visitors.nodes}
        totalLengthSelector={(data) => data.me.visitors.countBeforeFilter}
        defaultSortCol="id"
        defaultSortDir="desc"
      >
        {(rowsData) =>
          rowsData.map((row, i) => (
            <tr key={i}>
              <td>{row.id}</td>
              <TrimmedTd max={40}>
                {row.referrerUrl ? row.referrerUrl : "N/A"}
              </TrimmedTd>
              <TrimmedTd max={40}>{row.destinationUrl}</TrimmedTd>
              <td>{row.ip}</td>
              <TrimmedTd max={40}>{row.userAgent}</TrimmedTd>
              <td>{getDateFromStamp(row.createdAt)}</td>
            </tr>
          ))
        }
      </GQLDataTable>
    </ViewLayout>
  );
};

const GET_VISITORS = gql`
  query GET_VISITORS($filter: VisitorFilter) {
    me {
      id
      visitors(filter: $filter) {
        countBeforeFilter
        countAfterFilter
        nodes {
          id
          referrerUrl
          destinationUrl
          ip
          userAgent
          createdAt
        }
      }
    }
  }
`;

export default VisitorsPage;
