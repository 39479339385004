import React from "react";
import { Router } from "@reach/router";
import NotFoundPage from "../pages/NotFound";
import LogoutPage from "../pages/Logout";
import AdminUsersPage from "../pages/admin/Users";
import AdminVisitorsPage from "../pages/admin/Visits";
import AdminSalesPage from "../pages/admin/Sales";
import AdminPaymentsPage from "../pages/admin/Payments";
import AdminUserPage from "../pages/admin/User";
import AdminDashboardPage from "../pages/admin/Dashboard";
import AdminPayAffiliatesPage from "../pages/admin/PayAffiliates";

const AdminRoutes = () => (
  <Router>
    <AdminDashboardPage path="admin/" />
    <AdminVisitorsPage path="admin/visits" />
    <AdminUsersPage path="admin/users" />
    <AdminUserPage path="admin/user/:userId" />
    <AdminSalesPage path="admin/sales" />
    <AdminPaymentsPage path="admin/payments" />
    <AdminPayAffiliatesPage path="admin/pay-affiliates" />
    <LogoutPage path="/logout" />
    <NotFoundPage default />
  </Router>
);

export default AdminRoutes;
