import React from "react";
import ViewLayout from "../layouts/ViewLayout";

const PromoPage = () => {
  return (
    <ViewLayout title="Promotional Materials">
      <p>
        You can download our promotional materials from{" "}
        <a
          href="https://www.dropbox.com/sh/wf8u1rkzm59n31g/AADvnFSIURGzkFtjkwHl0S9ha?dl=0"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
      </p>
    </ViewLayout>
  );
};

export default PromoPage;
