import React from "react";
import ViewLayout from "../layouts/ViewLayout";
import gql from "graphql-tag";
import GQLDataTable from "../components/GQLDataTable";
import { getDateFromStamp } from "../lib/util";

const PaymentsPage = () => {
  const tableHeader = [
    { label: "Id", selector: "id", sortable: true },
    { label: "Amount", selector: "amount", sortable: true },
    { label: "Payment Date", selector: "createdAt", sortable: true },
  ];

  return (
    <ViewLayout title="Payments">
      <GQLDataTable
        query={GET_PAYMENTS}
        tableHeader={tableHeader}
        rowsDataSelector={(data) => data.me.payments.nodes}
        totalLengthSelector={(data) => data.me.payments.countBeforeFilter}
        defaultSortCol="createdAt"
        defaultSortDir="desc"
      >
        {(rowsData) =>
          rowsData.map((row, i) => (
            <tr key={i}>
              <td>{row.id}</td>
              <td>${row.amount.toFixed(2)} USD</td>
              <td>{getDateFromStamp(row.createdAt)}</td>
            </tr>
          ))
        }
      </GQLDataTable>
    </ViewLayout>
  );
};

const GET_PAYMENTS = gql`
  query GET_PAYMENTS($filter: PaymentFilter) {
    me {
      id
      payments(filter: $filter) {
        countBeforeFilter
        countAfterFilter
        nodes {
          id
          amount
          createdAt
        }
      }
    }
  }
`;

export default PaymentsPage;
