import React from "react";
import ViewLayout from "../layouts/ViewLayout";
import gql from "graphql-tag";
import GQLDataTable from "../components/GQLDataTable";
import { getDateFromStamp } from "../lib/util";
import Badge from "../components/UI/Badge";
import TrimmedTd from "../components/GQLDataTable/TrimmedTd";
import { SalesExportButton } from "../components/CSVExportButtons/SalesExportButton";

const SalesPage = () => {
  const tableHeader = [
    { label: "Id", selector: "id", sortable: true },
    { label: "Visit Ref.", selector: "visitRef" },
    { label: "Visit Dest.", selector: "visitDest" },
    { label: "Order", selector: "orderRef" },
    { label: "Sale", selector: "amount" },
    { label: "Commision", selector: "commisionAmount" },
    { label: "Payment", selector: "payment" },
    { label: "Status", selector: "status" },
    { label: "Sale Date", selector: "createdAt", sortable: true },
  ];

  return (
    <ViewLayout title="Referred Sales" ActionsComponent={SalesExportButton}>
      <GQLDataTable
        query={GET_SALES}
        tableHeader={tableHeader}
        rowsDataSelector={(data) => data.me.sales.nodes}
        totalLengthSelector={(data) => data.me.sales.countBeforeFilter}
        defaultSortCol="id"
        defaultSortDir="desc"
      >
        {(rowsData) =>
          rowsData.map((row, i) => (
            <tr key={i}>
              <td>{row.id}</td>
              <TrimmedTd max={40}>
                {row.visitor.referrerUrl ? row.visitor.referrerUrl : "N/A"}
              </TrimmedTd>

              <TrimmedTd max={40}>
                {row.visitor.destinationUrl
                  ? row.visitor.destinationUrl
                  : "N/A"}
              </TrimmedTd>
              <td>{row.orderRef}</td>
              <td>${row.amount.toFixed(2)} USD</td>
              <td>${row.commisionAmount.toFixed(2)} USD</td>
              <td>
                <Badge
                  warning={row.payment ? false : true}
                  success={row.payment ? true : false}
                >
                  {row.payment ? "PAID" : "NOT PAID"}
                </Badge>
              </td>
              <td>
                <Badge
                  danger={row.status === "REJECTED"}
                  warning={row.status === "PENDING"}
                  success={row.status === "APPROVED"}
                >
                  {row.status}
                </Badge>
              </td>
              <td>{getDateFromStamp(row.createdAt)}</td>
            </tr>
          ))
        }
      </GQLDataTable>
    </ViewLayout>
  );
};

const GET_SALES = gql`
  query GET_SALES($filter: SaleFilter) {
    me {
      id
      sales(filter: $filter) {
        countBeforeFilter
        countAfterFilter
        nodes {
          id
          orderRef
          amount
          commisionAmount
          status
          statusReason
          payment {
            id
          }
          visitor {
            id
            referrerUrl
            destinationUrl
          }
          createdAt
        }
      }
    }
  }
`;

export default SalesPage;
