import React from "react";
import gql from "graphql-tag";
import ViewLayout from "../../layouts/ViewLayout";
import GQLDataTable from "../../components/GQLDataTable";
import { getDateFromStamp } from "../../lib/util";
import TrimmedTd from "../../components/GQLDataTable/TrimmedTd";
import { AdminVisitorExportButton } from "../../components/CSVExportButtons/AdminVisitorExportButton";

const AdminVisitorsPage = () => {
  const tableHeader = [
    { label: "Id", selector: "id", sortable: true },
    { label: "Referrer", selector: "referrerUrl", sortable: true },
    { label: "Destination", selector: "destinationUrl", sortable: true },
    { label: "Ip", selector: "ip" },
    { label: "User Agent", selector: "userAgent" },
    { label: "Affiliate", selector: "visitor.user.email" },
    { label: "Created At", selector: "createdAt", sortable: true },
  ];

  return (
    <ViewLayout title="Visits" ActionsComponent={AdminVisitorExportButton}>
      <GQLDataTable
        query={GET_VISITORS}
        tableHeader={tableHeader}
        rowsDataSelector={(data) => data.visitors.nodes}
        totalLengthSelector={(data) => data.visitors.countBeforeFilter}
        defaultSortCol="createdAt"
        defaultSortDir="desc"
      >
        {(rowsData) =>
          rowsData.map((row, i) => (
            <tr key={i}>
              <td>{row.id}</td>
              <TrimmedTd max={40}>
                {row.referrerUrl ? row.referrerUrl : "N/A"}
              </TrimmedTd>
              <TrimmedTd max={40}>{row.destinationUrl}</TrimmedTd>
              <td>{row.ip}</td>
              <TrimmedTd max={40}>{row.userAgent}</TrimmedTd>
              <td>{row.user.email}</td>
              <td>{getDateFromStamp(row.createdAt)}</td>
            </tr>
          ))
        }
      </GQLDataTable>
    </ViewLayout>
  );
};

const GET_VISITORS = gql`
  query GET_VISITORS($filter: VisitorFilter) {
    visitors(filter: $filter) {
      countAfterFilter
      countBeforeFilter
      nodes {
        id
        referrerUrl
        destinationUrl
        ip
        userAgent
        createdAt
        user {
          id
          email
        }
      }
    }
  }
`;

export default AdminVisitorsPage;
