import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import AffiliateSidebar from "./components/Sidebar/AffiliateSidebar";
import AdminSidebar from "./components/Sidebar/AdminSidebar";
import Notification from "./components/Notifications";
import LoginPage from "./pages/Login";
import CreateLinkModal from "./components/CreateLinkModal";

import AdminRoutes from "./routes/AdminRoutes";
import AffiliateRoutes from "./routes/AffiliateRoutes";

const App = () => {
  const { token } = useSelector(state => state.user);
  if (!token) return <LoginPage />;

  const decodedToken = jwt_decode(token);
  const userIsAdmin = decodedToken.role === "ADMIN";

  const Sidebar = userIsAdmin ? AdminSidebar : AffiliateSidebar;
  const Routes = userIsAdmin ? AdminRoutes : AffiliateRoutes;

  return (
    <AppContainer>
      <Notification />
      <CreateLinkModal />
      <SideBarContainer>
        <Sidebar />
      </SideBarContainer>
      <RightSideContainer>
        <Routes />
      </RightSideContainer>
    </AppContainer>
  );
};

const AppContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SideBarContainer = styled.div`
  flex: 0 0 250px;
`;

const RightSideContainer = styled.div`
  flex: 1;
`;

export default App;
