import React from "react";
import gql from "graphql-tag";
import ViewLayout from "../../layouts/ViewLayout";
import GQLDataTable from "../../components/GQLDataTable";
import { getDateFromStamp } from "../../lib/util";
import Button from "../../components/UI/Button";
import { navigate } from "@reach/router";

const PaymentActions = () => {
  return (
    <Button onClick={() => navigate("/admin/pay-affiliates")}>
      Pay Affiliates
    </Button>
  );
};

const AdminPaymentsPage = () => {
  const tableHeader = [
    { label: "Id", selector: "id", sortable: true },
    { label: "Affiliate", selector: "user.email" },
    { label: "Amount", selector: "amount", sortable: true },
    { label: "Created At", selector: "createdAt", sortable: true },
  ];

  return (
    <ViewLayout title="Payments" ActionsComponent={PaymentActions}>
      <GQLDataTable
        query={GET_PAYMENTS}
        tableHeader={tableHeader}
        rowsDataSelector={(data) => data.payments.nodes}
        totalLengthSelector={(data) => data.payments.countBeforeFilter}
        defaultSortCol="createdAt"
        defaultSortDir="desc"
      >
        {(rowsData) =>
          rowsData.map((row, i) => (
            <tr key={i}>
              <td>{row.id}</td>
              <td>{row.user.email}</td>
              <td>${row.amount.toFixed(2)}</td>
              <td>{getDateFromStamp(row.createdAt)}</td>
            </tr>
          ))
        }
      </GQLDataTable>
    </ViewLayout>
  );
};

const GET_PAYMENTS = gql`
  query GET_PAYMENTS($filter: PaymentFilter) {
    payments(filter: $filter) {
      countAfterFilter
      countBeforeFilter
      nodes {
        id
        user {
          id
          email
        }
        amount
        createdAt
      }
    }
  }
`;

export default AdminPaymentsPage;
