import React from "react";
import styled from "styled-components";

const ViewLayout = ({ title, ActionsComponent, children }) => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitleContainer>{title}</HeaderTitleContainer>
        <HeaderActionsContainer>
          {ActionsComponent ? <ActionsComponent /> : null}
        </HeaderActionsContainer>
      </HeaderContainer>
      <ContentContainer>{children}</ContentContainer>
    </>
  );
};

const ContentContainer = styled.div`
  padding: 10px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

const HeaderTitleContainer = styled.div`
  font-size: 35px;
  font-weight: 800;
`;

const HeaderActionsContainer = styled.div`
  margin-left: auto;
`;

export default ViewLayout;
