import styled from "styled-components";

const Table = styled.table`
  display: table;
  border-collapse: collapse;
  width: 100%;
  text-align: ${props => (props.align ? props.align : "left")};
  border: 1px solid rgba(0, 0, 0, 0.12);

  thead {
    font-weight: 600;

    th {
      background: #fafafb;
      border-bottom: 1px solid #e8e8e8;
      line-height: 2;
      padding-top: 15px;
      padding-bottom: 15px;
      :first-child {
        padding-left: 15px;
        border-radius: 10px 0 0 0;
      }
      :last-child {
        border-radius: 0 10px 0 0;
      }
    }
  }

  tbody {
    tr {
      /* :nth-child(even) {
        background-color: #fafafb;
      } */
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    td {
      padding-top: 16px;
      padding-bottom: 17px;

      :first-child {
        padding-left: 16px;
      }
    }
  }
`;

export default Table;
