import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import useForm from "../../lib/useForm";
import Textfield from "../UI/Form/Textfield";
import FormGroup from "../UI/Form/FormGroup";
import Label from "../UI/Form/Label";
import FormHelperText from "../UI/Form/FormHelperText";
import Button from "../UI/Button";
import Select from "../UI/Form/Select";

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Email is needed")
    .email("Enter A Valid Email Address"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  addressLine1: Yup.string().required("Address is required"),
  zip: Yup.string().required("Zip is required"),
  paymentMethod: Yup.string().required("Payment method is required"),
  commisionPercent: Yup.string().required("Commision percent is required"),
  minimumPayout: Yup.number().required("Minimum payout is required"),
  promoMethod: Yup.string().required("Promo method is required"),
  status: Yup.string().required("Status is required")
});

const initialFormDataFactory = userData => ({
  id: userData.id,
  email: userData.email,
  companyName: userData.companyName,
  firstName: userData.firstName,
  lastName: userData.lastName,
  country: userData.country,
  state: userData.state,
  city: userData.city,
  addressLine1: userData.addressLine1,
  addressLine2: userData.addressLine2,
  zip: userData.zip,
  paymentMethod: userData.paymentMethod,
  wireAccount: userData.wireAccount,
  paypalEmail: userData.paypalEmail,
  commisionPercent: userData.commisionPercent,
  minimumPayout: userData.minimumPayout,
  promoMethod: userData.promoMethod,
  status: userData.status
});

const EditUserForm = ({ userData, onSubmit }) => {
  const [formField, formApi, formState] = useForm(
    initialFormDataFactory(userData),
    validationSchema
  );

  console.log("formState", formState);
  console.log("cast", formApi.getAllValues());

  return (
    <Container>
      <FormGroup>
        <Label htmlFor="email"> Email </Label>
        <Textfield {...formField.email} />
        <FormHelperText error>
          {formApi.getErrorMessage("email")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="status"> Status </Label>
        <Select {...formField.status}>
          <option value="PENDING">PENDING</option>
          <option value="REJECTED">REJECTED</option>
          <option value="APPROVED">APPROVED</option>
        </Select>
        <FormHelperText error>
          {formApi.getErrorMessage("status")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="companyName"> Company Name </Label>
        <Textfield {...formField.companyName} />
        <FormHelperText error>
          {formApi.getErrorMessage("companyName")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="firstName"> First Name </Label>
        <Textfield {...formField.firstName} />
        <FormHelperText error>
          {formApi.getErrorMessage("firstName")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="lastName"> Last Name </Label>
        <Textfield {...formField.lastName} />
        <FormHelperText error>
          {formApi.getErrorMessage("lastName")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="country"> Country </Label>
        <Textfield {...formField.country} />
        <FormHelperText error>
          {formApi.getErrorMessage("country")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="state"> State </Label>
        <Textfield {...formField.state} />
        <FormHelperText error>
          {formApi.getErrorMessage("state")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="city"> City </Label>
        <Textfield {...formField.city} />
        <FormHelperText error>{formApi.getErrorMessage("city")}</FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="addressLine1"> Address </Label>
        <Textfield {...formField.addressLine1} />
        <FormHelperText error>
          {formApi.getErrorMessage("addressLine1")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="addressLine2"> Address Line 2 </Label>
        <Textfield {...formField.addressLine2} />
        <FormHelperText error>
          {formApi.getErrorMessage("addressLine2")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="zip"> Zip </Label>
        <Textfield {...formField.zip} />
        <FormHelperText error>{formApi.getErrorMessage("zip")}</FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="paymentMethod"> Payment Method</Label>
        <Select {...formField.paymentMethod}>
          <option value="PAYPAL"> PAYPAL </option>
          <option value="WIRE"> WIRE </option>
        </Select>
        <FormHelperText error>
          {formApi.getErrorMessage("paymentMethod")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="wireAccount"> Wire Account</Label>
        <Textfield {...formField.wireAccount} />
        <FormHelperText error>
          {formApi.getErrorMessage("wireAccount")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="paypalEmail"> Paypal Email </Label>
        <Textfield {...formField.paypalEmail} />
        <FormHelperText error>
          {formApi.getErrorMessage("paypalEmail")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="commisionPercent"> Commision Percent </Label>
        <Textfield {...formField.commisionPercent} type="number" />
        <FormHelperText error>
          {formApi.getErrorMessage("commisionPercent")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="minimumPayout"> Minimum Payout </Label>
        <Textfield {...formField.minimumPayout} type="number" />
        <FormHelperText error>
          {formApi.getErrorMessage("minimumPayout")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="promoMethod"> Promotional Method </Label>
        <Textfield {...formField.promoMethod} />
        <FormHelperText error>
          {formApi.getErrorMessage("promoMethod")}
        </FormHelperText>
      </FormGroup>

      <FormGroup>
        <Button
          fullWidth
          onClick={() => onSubmit(formApi.getAllValues())}
          disabled={!formApi.isValid}
        >
          Save User
        </Button>
      </FormGroup>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
`;

export default EditUserForm;
