import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider as ReduxProvider } from "react-redux";
import { reduxStore } from "./reducers";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo-hooks";
import { ModalProvider } from "styled-react-modal";
import { Normalize } from "styled-normalize";
import { GlobalStyle } from "./style/globalStyle";

export const apolloClient = new ApolloClient({
  // uri: "http://localhost:8080/graphql",
  uri: "https://affiliate.vieci-secure-systems.com/graphql",
  request: async (operation) => {
    const token = localStorage.getItem("token") || "";
    operation.setContext({ headers: { authorization: token } });
  },
});

const Root = (
  <ApolloProvider client={apolloClient}>
    <ReduxProvider store={reduxStore}>
      <ModalProvider>
        <Normalize />
        <GlobalStyle />
        <App />
      </ModalProvider>
    </ReduxProvider>
  </ApolloProvider>
);

ReactDOM.render(Root, document.getElementById("root"));
