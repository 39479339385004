// Actions
const SHOW = "notification/SHOW";
const HIDE = "notification/HIDE";

// Initial State
const INITIAL_STATE = {
  isOpen: false,
  message: "",
  type: ""
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        isOpen: true,
        type: action.payload.type,
        message: action.payload.message
      };

    case HIDE:
      return {
        ...state,
        isOpen: false,
        type: "",
        message: ""
      };

    default:
      return state;
  }
}

// Action Creators
export const showNotification = (
  notificationType,
  message,
  expiryTime = 5000
) => dispatch => {
  dispatch({
    type: SHOW,
    payload: {
      type: notificationType,
      message: message
    }
  });
  setTimeout(() => {
    dispatch(hideNotification());
  }, expiryTime);
};

export const hideNotification = () => {
  return { type: HIDE };
};
