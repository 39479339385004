import React from "react";
import gql from "graphql-tag";
import ViewLayout from "../../layouts/ViewLayout";
import GQLDataTable from "../../components/GQLDataTable";
import { getDateFromStamp } from "../../lib/util";
import Button from "../../components/UI/Button";
import { useMutation } from "react-apollo-hooks";
import { useDispatch } from "react-redux";
import { showNotification } from "../../reducers/notification";

const AdminPayAffiliatesPage = () => {
  const [createPayment] = useMutation(CREATE_PAYMENT);
  const dispatch = useDispatch();

  const tableHeader = [
    { label: "Id", selector: "id", sortable: true },
    { label: "First Name", selector: "firstName" },
    { label: "Last Name", selector: "lastName", sortable: true },
    { label: "Email", selector: "email", sortable: true },
    { label: "To Pay" },
    { label: "Min. Payout", selector: "minimumPayout", sortable: true },
    { label: "Payment Method", selector: "paymentMethod", sortable: true },
    { label: "Payment Details" },
    { label: "Created At", selector: "createdAt", sortable: true },
    { label: "Action", selector: "", sortable: true },
  ];

  const handlePaymentClick = async (affiliateId, saleIds, toPay, tableApi) => {
    const formattedSaleIds = saleIds.map((sale) => Number(sale.id));
    console.log("affiliateId", affiliateId);
    console.log("formattedSaleIds", formattedSaleIds);
    try {
      await createPayment({
        variables: {
          input: {
            userId: Number(affiliateId),
            saleIds: formattedSaleIds,
            amount: Number(toPay),
          },
        },
      });
      tableApi.refetch();
      dispatch(showNotification("success", `Affiliate was marked as paid`));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ViewLayout title="Pay Affiliates">
      <GQLDataTable
        query={GET_PAYABLE_AFFILIATES}
        tableHeader={tableHeader}
        rowsDataSelector={(data) => data.payableUsers.nodes}
        totalLengthSelector={(data) => data.payableUsers.countBeforeFilter}
        defaultSortCol="createdAt"
        defaultSortDir="desc"
      >
        {(rowsData, tableApi) =>
          rowsData.map((row, i) => (
            <tr key={i}>
              <td>{row.id}</td>
              <td>{row.firstName}</td>
              <td>{row.lastName}</td>
              <td>{row.email}</td>
              <td>${row.toPay.toFixed(2)}</td>
              <td>${row.minimumPayout.toFixed(2)}</td>
              <td>{row.paymentMethod}</td>
              <td>{row.paypalEmail ? row.paypalEmail : row.wireAccount}</td>
              <td>{getDateFromStamp(row.createdAt)}</td>
              <td>
                <Button
                  onClick={() =>
                    handlePaymentClick(
                      row.id,
                      row.sales.nodes,
                      row.toPay,
                      tableApi
                    )
                  }
                >
                  Pay
                </Button>
              </td>
            </tr>
          ))
        }
      </GQLDataTable>
    </ViewLayout>
  );
};

const GET_PAYABLE_AFFILIATES = gql`
  query GET_PAYABLE_AFFILIATES($filter: UserFilter) {
    payableUsers(filter: $filter) {
      countBeforeFilter
      countAfterFilter
      nodes {
        id
        firstName
        lastName
        email
        toPay
        minimumPayout
        paymentMethod
        paypalEmail
        wireAccount
        createdAt
        sales(filter: { paymentId_isNull: true, status_eq: APPROVED }) {
          nodes {
            id
          }
        }
      }
    }
  }
`;

const CREATE_PAYMENT = gql`
  mutation createPayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
    }
  }
`;

export default AdminPayAffiliatesPage;
