import React from "react";
import { Router } from "@reach/router";
import DashboardPage from "../pages/Dashboard";
import NotFoundPage from "../pages/NotFound";
import LogoutPage from "../pages/Logout";
import VisitorsPage from "../pages/Visits";
import SalesPage from "../pages/Sales";
import PaymentsPage from "../pages/Payments";
import PromoPage from "../pages/Promo";

const AffiliateRoutes = () => (
  <Router>
    <DashboardPage path="/" />
    <VisitorsPage path="/visits" />
    <SalesPage path="/sales" />
    <PaymentsPage path="/payments" />
    <PromoPage path="/promo" />
    <LogoutPage path="/logout" />
    <NotFoundPage default />
  </Router>
);

export default AffiliateRoutes;
