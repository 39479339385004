import React from "react";
import ViewLayout from "../layouts/ViewLayout";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import Loader from "../components/Loader";
import GqlError from "../components/GqlError";
import styled from "styled-components";
import VisitsChart from "../components/VisitsChart";
import SalesChart from "../components/SalesChart";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const GET_STATS = gql`
  query GET_STATS($startDate: String!, $endDate: String!) {
    me {
      id
      visitorsCount(startDate: $startDate, endDate: $endDate)
      commisionAmount(startDate: $startDate, endDate: $endDate)
      estimatedNextPaymentAmount
      groupedVisitors(startDate: $startDate, endDate: $endDate) {
        date
        total
      }
      groupedCommisions(startDate: $startDate, endDate: $endDate) {
        date
        total
      }
    }
  }
`;

const DashboardPage = () => {
  const [startDate, setStartDate] = React.useState(
    new Date(new Date().getTime() - 86400000 * 7)
  );
  const [endDate, setEndDate] = React.useState(new Date());

  const queryOptions = {
    variables: {
      startDate: startDate.toDateString(),
      endDate: endDate.toDateString(),
    },
  };

  const { data, error, loading } = useQuery(GET_STATS, queryOptions);

  if (loading) return <Loader />;
  if (error) return <GqlError error={error} />;

  return (
    <ViewLayout title="Dashboard">
      <SummaryContainer>
        <StatCard>
          <StatNumber>{data.me.visitorsCount}</StatNumber>
          <StatDescription>Unique Visitors</StatDescription>
        </StatCard>
        <StatCard>
          <StatNumber>
            ${(data.me.visitorsCount / data.me.commisionAmount).toFixed(2)}
          </StatNumber>
          <StatDescription>EPV</StatDescription>
        </StatCard>
        <StatCard>
          <StatNumber>${data.me.commisionAmount.toFixed(2)}</StatNumber>
          <StatDescription>Commisions</StatDescription>
        </StatCard>
        <StatCard>
          <StatNumber>
            ${data.me.estimatedNextPaymentAmount.toFixed(2)}
          </StatNumber>
          <StatDescription>Estimated Next Payment</StatDescription>
        </StatCard>
      </SummaryContainer>

      <ChartCard>
        <ChartHeader>
          Visits & Pageviews{" "}
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />{" "}
          to{" "}
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            endDate={endDate}
            minDate={startDate}
          />
        </ChartHeader>
        <ChartBody>
          <VisitsChart groupedVisitors={data.me.groupedVisitors} />
        </ChartBody>
      </ChartCard>

      <ChartCard>
        <ChartHeader>Commisions USD</ChartHeader>
        <ChartBody>
          <SalesChart groupedCommisions={data.me.groupedCommisions} />
        </ChartBody>
      </ChartCard>
    </ViewLayout>
  );
};

const SummaryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StatCard = styled.div`
  flex: 0 0 23%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  :hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  text-align: center;
`;

const ChartCard = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  :hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  text-align: center;
`;

const ChartHeader = styled.div`
  width: 100%;
  padding: 10px;
  background: #fafafb;
  border-bottom: 1px solid #e8e8e8;
  font-size: 20px;
  font-weight: 800;
  text-align: left;
`;

const ChartBody = styled.div`
  width: 100%;
  height: 300px;
`;

const StatNumber = styled.div`
  flex: 0 0 100%;
  font-size: 30px;
  font-weight: 800;
`;

const StatDescription = styled.div`
  flex: 0 0 100%;
  font-size: 14px;
`;

export default DashboardPage;
