import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {

  }

  body {
    font-family: 'Source Sans Pro', sans-serif;
    color: #444444;
    font-size: 16px;
    line-height: 1.5;
    position: relative;
  }

  div {
      box-sizing:border-box;
  }

  a, button {
    cursor: pointer;
    text-decoration:none;
  }

`;

export { GlobalStyle };
