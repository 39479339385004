import React from "react";
import styled from "styled-components";
import { Close as CloseIcon } from "styled-icons/material/Close";
import { CheckCircle as SuccessIcon } from "styled-icons/fa-solid/CheckCircle";
import { ExclamationCircle as ErrorIcon } from "styled-icons/fa-solid/ExclamationCircle";
import { css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { hideNotification } from "../../reducers/notification";

const TYPE_ERROR = "error";
const TYPE_WARNING = "warning";
const TYPE_SUCCESS = "success";

const Notification = () => {
  const { isOpen, message, type } = useSelector(state => state.notification);
  const dispatch = useDispatch();

  return (
    <NotificationContainer isOpen={isOpen}>
      <Bubble type={type}>
        <NotificationIconContainer>
          {renderIconForType(type)}
        </NotificationIconContainer>
        <NotificationContentContainer> {message} </NotificationContentContainer>
        <NotificationExitContainer>
          <CloseIcon size={16} onClick={() => dispatch(hideNotification())} />
        </NotificationExitContainer>
      </Bubble>
    </NotificationContainer>
  );
};

const renderIconForType = type => {
  switch (type) {
    case TYPE_WARNING:
      return <ErrorIcon size={20} />;
    case TYPE_SUCCESS:
      return <SuccessIcon size={20} />;
    case TYPE_ERROR:
      return <ErrorIcon size={20} />;
    default:
      return <SuccessIcon size={20} />;
  }
};

const NotificationContainer = styled.div`
  display: none;

  ${props =>
    props.isOpen &&
    css`
      display: block;
    `}

  box-sizing:border-box;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10000;
  width: 360px;

  @media (max-width: 700px) {
    width: 100%;
    right: 0px;
    padding: 5px;
  }
`;

const Bubble = styled.div`
    display:flex; 
    flex-wrap:wrap;

    ${props =>
      props.type === TYPE_SUCCESS &&
      css`
        background-color: #4d7703;
        color: #ffffff;

        ${NotificationIconContainer} {
          background-color: #2d4602;
          color: #ffffff;
        }
      `}

    ${props =>
      props.type === TYPE_ERROR &&
      css`
        background-color: #c71b01;
        color: #ffffff;

        ${NotificationIconContainer} {
          background-color: #6f0f00;
          color: #ffffff;
        }
      `}

    ${props =>
      props.type === TYPE_WARNING &&
      css`
        background-color: #fffae6;
        color: #feaa01;

        ${NotificationIconContainer} {
          background-color: #feaa01;
          color: #fffae6;
        }
      `}

    min-height: 60px;
    border-radius:7px;
    box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px;
`;

const NotificationIconContainer = styled.div`
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  position: relative;
  text-align: center;
  flex: 0 0 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationContentContainer = styled.div`
  flex: 1;
  /* margin-left:8px; */
  padding: 6px;
  font-weight: 700;
`;

const NotificationExitContainer = styled.div`
  flex: 0 0 20px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  align-items: flex-start;
`;

export default Notification;
