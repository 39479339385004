import styled from "styled-components";
import { css } from "styled-components";

const Textfield = styled.input`
  border: 1px solid transparent;
  border-radius: 4px;
  height: 30px;
  max-width: 100%;
  width: 100%;
  border-color: #00d1b2;

  ${props =>
    props.info &&
    css`
      border-color: #209cee;
    `}

  ${props =>
    props.success &&
    css`
      border-color: #23d160;
    `}
    
  ${props =>
    props.warning &&
    css`
      border-color: #ffdd57;
      color: black;
    `}

  ${props =>
    (props.danger || props.error) &&
    css`
      border-color: #ff3860;
    `}

 
`;

export default Textfield;
