import React from "react";
import styled from "styled-components";
import Select from "../UI/Form/Select";
import { NavigateNext as NavigateNextIcon } from "styled-icons/material/NavigateNext";
import { NavigateBefore as NavigateBeforeIcon } from "styled-icons/material/NavigateBefore";

const Pagination = ({
  itemsLength,
  currentPage,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const totalPageCount = Math.ceil(itemsLength / rowsPerPage);

  const handleChangePage = (nextPage) => {
    if (nextPage < 0) return null;
    if (nextPage === totalPageCount) return null;

    onChangePage(nextPage);
  };
  return (
    <Container>
      <LengthContainer>
        <LengthMessage>Results Per Page: </LengthMessage>
        <LengthSelector>
          <Select
            onChange={(e) => onChangeRowsPerPage(e.target.value)}
            value={rowsPerPage}
          >
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="40">40</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </Select>
        </LengthSelector>
      </LengthContainer>

      <PaginationContainer>
        <StatusContainer>
          {(currentPage + 1) * rowsPerPage - rowsPerPage + 1} -{" "}
          {(currentPage + 1) * rowsPerPage} of {itemsLength}
        </StatusContainer>
        <StyledNavigateBeforeIcon
          size={30}
          onClick={() => handleChangePage(currentPage - 1)}
          disabled={currentPage === 0}
        />

        <StyledNavigateNextIcon
          size={30}
          onClick={() => handleChangePage(currentPage + 1)}
          disabled={currentPage + 1 === totalPageCount}
        />
      </PaginationContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const LengthContainer = styled.div`
  display: flex;
  width: 200px;
`;

const LengthMessage = styled.div`
  flex: 0 0 70%;
`;

const LengthSelector = styled.div`
  flex: 0 0 30%;
`;

const StatusContainer = styled.div`
  margin-left: auto;
  margin-right: 10px;
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const StyledNavigateBeforeIcon = styled(NavigateBeforeIcon)`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => (props.disabled ? "#c9c9c9" : "#444444")};
  border: 1px solid #c9c9c9;
  margin-right: 5px;
`;

const StyledNavigateNextIcon = styled(NavigateNextIcon)`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => (props.disabled ? "#c9c9c9" : "#444444")};
  border: 1px solid #c9c9c9;
  margin-left: 5px;
`;

export default Pagination;
