import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import notificationReducer from "./notification";
import userReducer from "./user";
import commonReducer from "./common";

const rootReducer = combineReducers({
  notification: notificationReducer,
  user: userReducer,
  common: commonReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const reduxStore = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
