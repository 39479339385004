import React from "react";
import styled from "styled-components";
import { css } from "styled-components";
import { Link, Match } from "@reach/router";

const NavLink = ({ to, children }) => (
  <Match path={to}>
    {props =>
      props.match ? (
        <Link to={to}>
          <MenuItem active={true}>{children}</MenuItem>
        </Link>
      ) : (
        <Link to={to}>
          <MenuItem active={false}>{children}</MenuItem>
        </Link>
      )
    }
  </Match>
);

const MenuItem = styled.div`
  display: flex;
  text-decoration:none;
  ${props =>
    props.active &&
    css`
      background-color: #5432ed;
    `}
  font-size: ${props => (props.active ? 18 : 16)}px;
  font-weight: ${props => (props.active ? 800 : 600)};
  color: ${props => (props.active ? "white" : "#949ba3")};

  padding-top:10px;
  padding-bottom:10px;
  padding-left: 35px;
  margin-bottom: 15px;
`;

export default NavLink;
