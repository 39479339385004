import React from "react";

const initialStateFactory = initialValues => {
  let initialState = {
    meta: {
      isValid: false,
      focusedField: ""
    }
  };

  Object.keys(initialValues).forEach(fieldKey => {
    initialState[fieldKey] = {
      value: initialValues[fieldKey],
      errors: [],
      meta: {
        touched: false,
        dirty: false,
        type: typeof initialValues[fieldKey]
      }
    };
  });

  return initialState;
};

const formFieldFactory = (
  formInitialData,
  state,
  setState,
  validationSchema
) => {
  let formField = {};

  Object.keys(formInitialData).forEach(formFieldKey => {
    formField[formFieldKey] = {
      id: formFieldKey,
      name: formFieldKey,
      value: state[formFieldKey].value ? state[formFieldKey].value : "",
      error: state[formFieldKey].errors.length ? true : false,
      success: state[formFieldKey].errors.length ? false : true,

      onChange: e =>
        onChangeHandler(state, setState, formFieldKey, e, validationSchema),
      onFocus: () => onFocusHandler(state, setState, formFieldKey),
      onBlur: () => onBlurHandler(state, setState, formFieldKey),
      onClick: () => onClickHandler(state, setState, formFieldKey)
    };
  });

  return formField;
};

const onChangeHandler = async (
  state,
  setState,
  fieldKey,
  fieldEvent,
  validationSchema
) => {
  const newState = {
    ...state,
    [fieldKey]: {
      ...state[fieldKey],
      value: fieldEvent.target ? fieldEvent.target.value : fieldEvent.value,
      meta: {
        ...state[fieldKey].meta,
        dirty: true
      }
    }
  };

  setState(newState);

  validateForm(newState, setState, validationSchema);
};

const onBlurHandler = (state, setState, fieldKey) => {
  setState({
    ...state,
    meta: {
      ...state.meta,
      focusedField: null
    }
  });
};

const onFocusHandler = (state, setState, fieldKey) => {
  setState({
    ...state,
    meta: {
      ...state.meta,
      focusedField: fieldKey
    },
    [fieldKey]: {
      ...state[fieldKey],
      meta: {
        ...state[fieldKey].meta,
        touched: true
      }
    }
  });
};

const onClickHandler = (state, setState, fieldKey) => {
  setState({
    ...state,
    [fieldKey]: {
      ...state[fieldKey],
      meta: {
        ...state[fieldKey].meta,
        touched: true
      }
    }
  });
};

const typeCast = (type, value) => {
  if (type === "number") return Number(value);
  if (type === "string") return String(value);
};

const getDataFromState = state => {
  let dataFromState = {};

  Object.keys(state).forEach(formKey => {
    dataFromState = {
      ...dataFromState,
      [formKey]: state[formKey].meta
        ? typeCast(state[formKey].meta.type, state[formKey].value)
        : state[formKey].value
    };
  });

  delete dataFromState.meta;

  return dataFromState;
};

const validateForm = async (state, setState, validationSchema) => {
  const newState = { ...state };
  newState.meta.isValid = true;
  const formData = getDataFromState(state);

  Object.keys(formData).forEach(fieldKey => {
    newState[fieldKey].errors = [];
  });

  try {
    await validationSchema.validate(formData, { abortEarly: false });
  } catch (errors) {
    errors.inner.forEach(error => {
      newState[error.path].errors = error.errors;
    });
    newState.meta.isValid = false;
  }

  setState(newState);
};

const formatErrors = (formKey, state) => {
  const errors = state[formKey].errors;
  if (state.meta.focusedField === formKey) return "";
  if (!state[formKey].meta.dirty) return "";
  if (!errors.length) return "";
  else return errors.join(", ");
};

const useForm = (formInitialData, validationSchema) => {
  const [state, setState] = React.useState(
    initialStateFactory(formInitialData)
  );

  const formField = formFieldFactory(
    formInitialData,
    state,
    setState,
    validationSchema
  );

  React.useEffect(() => {
    validateForm(state, setState, validationSchema);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formApi = {
    getErrorMessage: formKey => formatErrors(formKey, state),
    getAllValues: () => getDataFromState(state),
    getFieldValue: formKey => state[formKey].value,
    getFocusedField: () => state.meta.focusedField,
    resetForm: () => setState(initialStateFactory(formInitialData)),
    setValues: updatedFields => {
      const newState = { ...state };
      Object.keys(updatedFields).forEach(key => {
        newState[key].value =
          updatedFields[key] === null ? "" : updatedFields[key];
      });

      setState(newState);

      validateForm(newState, setState, validationSchema);
    },
    isValid: state.meta.isValid
  };

  return [formField, formApi, state];
};
export default useForm;
