import React from "react";
import gql from "graphql-tag";
import ViewLayout from "../../layouts/ViewLayout";
import GQLDataTable from "../../components/GQLDataTable";
import { getDateFromStamp } from "../../lib/util";
import Badge from "../../components/UI/Badge";
import { AdminSalesExportButton } from "../../components/CSVExportButtons/AdminSalesExportButton";

const AdminSalesPage = () => {
  const tableHeader = [
    { label: "Id", selector: "id", sortable: true },
    { label: "Affiliate", selector: "user.email" },
    { label: "OrderRef", selector: "orderRef", sortable: true },
    { label: "Amount", selector: "amount", sortable: true },
    { label: "Commision", selector: "commisionAmount", sortable: true },
    { label: "Status", selector: "status", sortable: true },
    { label: "Sale Date", selector: "createdAt", sortable: true },
  ];

  return (
    <ViewLayout title="Sales" ActionsComponent={AdminSalesExportButton}>
      <GQLDataTable
        query={GET_SALES}
        tableHeader={tableHeader}
        rowsDataSelector={(data) => data.sales.nodes}
        totalLengthSelector={(data) => data.sales.countAfterFilter}
        defaultSortCol="createdAt"
        defaultSortDir="desc"
      >
        {(rowsData) =>
          rowsData.map((row, i) => (
            <tr key={i}>
              <td>{row.id}</td>
              <td>{row.user.email}</td>
              <td>{row.orderRef}</td>
              <td>${row.amount.toFixed(2)}</td>
              <td>${row.commisionAmount.toFixed(2)}</td>
              <td>
                <Badge
                  danger={row.status === "REJECTED"}
                  warning={row.status === "PENDING"}
                  success={row.status === "APPROVED"}
                >
                  {row.status}
                </Badge>
              </td>
              <td>{getDateFromStamp(row.createdAt)}</td>
            </tr>
          ))
        }
      </GQLDataTable>
    </ViewLayout>
  );
};

const GET_SALES = gql`
  query GET_SALES($filter: SaleFilter) {
    sales(filter: $filter) {
      countAfterFilter
      countBeforeFilter
      nodes {
        id
        user {
          id
          email
        }
        orderRef
        amount
        commisionAmount
        status
        statusReason
        createdAt
      }
    }
  }
`;

export default AdminSalesPage;
