import styled from "styled-components";
import { css } from "styled-components";

const Button = styled.button`
  display: inline-block;
  border-radius: 4px;
  font-size: ${props => (props.size ? props.size : 17)}px;
  padding: 8px;
  font-weight: 600;
  color: white;
  background-color: #5432ed;
  box-sizing:border-box;
  text-align:center;
  border:none;

  ${props =>
    props.info &&
    css`
      background-color: #209cee;
    `}

  ${props =>
    props.success &&
    css`
      background-color: #23d160;
    `}
  ${props =>
    props.warning &&
    css`
      background-color: #ffdd57;
      color: black;
    `}

  ${props =>
    props.danger &&
    css`
      background-color: #ff3860;
    `}

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

`;

export default Button;
