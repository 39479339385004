import React from "react";
import * as Yup from "yup";
import useForm from "../lib/useForm";
import Button from "../components/UI/Button";
import Textfield from "../components/UI/Form/Textfield";
import FormGroup from "../components/UI/Form/FormGroup";
import Label from "../components/UI/Form/Label";
import FormHelperText from "../components/UI/Form/FormHelperText";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import gql from "graphql-tag";
import { useMutation, useApolloClient } from "react-apollo-hooks";
import { navigate } from "@reach/router";
import { updateToken } from "../reducers/user";
import jwt_decode from "jwt-decode";
import Loader from "../components/Loader";

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      token
    }
  }
`;

const formInitialData = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Email is needed")
    .email("Enter A Valid Email Address"),
  password: Yup.string().required("Enter Password"),
});

const LoginPage = () => {
  const client = useApolloClient();
  const [formField, formApi] = useForm(formInitialData, validationSchema);
  const [login, { loading }] = useMutation(LOGIN);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      const formData = formApi.getAllValues();
      const { data } = await login({
        variables: {
          email: formData.email,
          password: formData.password,
        },
      });

      const decodedToken = jwt_decode(data.login.token);
      const userIsAdmin = decodedToken.role === "ADMIN";

      if (userIsAdmin) {
        navigate("/admin");
      } else {
        navigate("/");
      }

      client.resetStore();
      dispatch(updateToken(data.login.token));
    } catch (e) {
      setErrorMessage(e.graphQLErrors[0].message);
    }
  };

  return (
    <PageContainer>
      <FocusContainer>
        <Card>
          <Loader isLoading={loading} />
          <Heading>Sign in</Heading>
          <FormGroupContainer>
            <FormGroup>
              <Label htmlFor="email"> Email </Label>
              <Textfield {...formField.email} />
              <FormHelperText error>
                {formApi.getErrorMessage("email")}
              </FormHelperText>
            </FormGroup>
          </FormGroupContainer>
          <FormGroupContainer>
            <FormGroup>
              <Label htmlFor="password"> Password </Label>
              <Textfield type="password" {...formField.password} />
              <FormHelperText error>
                {formApi.getErrorMessage("password")}
              </FormHelperText>
            </FormGroup>
          </FormGroupContainer>
          <FormGroupContainer>
            <FormGroup>
              {errorMessage && (
                <ErrorContainer> {errorMessage} </ErrorContainer>
              )}
              <Button
                fullWidth
                onClick={handleSubmit}
                disabled={!formApi.isValid}
              >
                Login
              </Button>
            </FormGroup>
          </FormGroupContainer>
        </Card>
      </FocusContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  background: #001427;
`;

const FocusContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  max-width: 600px;
  min-height: 100vh;
`;

const Card = styled.div`
  flex: 0 0 100%;
  background: white;
  padding: 20px;
  border-radius: 6px;
`;

const FormGroupContainer = styled.div`
  flex: 0 0 100%;
`;

const Heading = styled.div`
  flex: 0 0 100%;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
`;

const ErrorContainer = styled.div`
  padding: 30px;
  text-align: center;
  color: tomato;
  font-weight: 800;
`;

export default LoginPage;
