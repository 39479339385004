import React from "react";
import styled from "styled-components";
import { AtomSpinner } from "react-epic-spinners";

const Loader = ({ isLoading = true, ...rest }) => {
  return (
    <LoaderContainer isLoading={isLoading} {...rest}>
      <AtomSpinnerContainer>
        <AtomSpinner color="white" />
      </AtomSpinnerContainer>
    </LoaderContainer>
  );
};

export default Loader;

const AtomSpinnerContainer = styled.div`
  opacity: 1;
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 769px) {
    width: 100%;
  }

  display: ${props => (props.isLoading ? "flex" : "none")};
  background: black;
  opacity: 0.8;
  justify-content: center;
  align-items: center;
  z-index: 100000;
`;
