import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import ViewLayout from "../../layouts/ViewLayout";
import Loader from "../../components/Loader";
import GqlError from "../../components/GqlError";
import styled from "styled-components";
import VisitsChart from "../../components/VisitsChart";
import SalesChart from "../../components/SalesChart";

const GET_ADMIN_STATS = gql`
  query GET_ADMIN_STATS($startDate: String!, $endDate: String!) {
    pageviewsTotalCount(startDate: $startDate, endDate: $endDate)
    visitorsTotalCount(startDate: $startDate, endDate: $endDate)
    commisionTotalAmount(startDate: $startDate, endDate: $endDate)
    groupedAllPageviews(startDate: $startDate, endDate: $endDate) {
      date
      total
    }
    groupedAllVisitors(startDate: $startDate, endDate: $endDate) {
      date
      total
    }
    groupedAllCommisions(startDate: $startDate, endDate: $endDate) {
      date
      total
    }
  }
`;

const AdminDashboardPage = () => {
  const queryOptions = {
    variables: {
      startDate: new Date(new Date().getTime() - 86400000 * 7).toDateString(),
      endDate: new Date().toDateString()
    }
  };

  const { data, error, loading } = useQuery(GET_ADMIN_STATS, queryOptions);

  if (loading) return <Loader />;
  if (error) return <GqlError error={error} />;

  return (
    <ViewLayout title="Dashboard">
      <SummaryContainer>
        <StatCard>
          <StatNumber>{data.pageviewsTotalCount}</StatNumber>
          <StatDescription>Pageviews</StatDescription>
        </StatCard>
        <StatCard>
          <StatNumber>{data.visitorsTotalCount}</StatNumber>
          <StatDescription>Visitors</StatDescription>
        </StatCard>
        <StatCard>
          <StatNumber>${data.commisionTotalAmount.toFixed(2)}</StatNumber>
          <StatDescription>Commisions</StatDescription>
        </StatCard>
        {/* <StatCard>
          <StatNumber>$3,273</StatNumber>
          <StatDescription>Estimated Next Payment</StatDescription>
        </StatCard> */}
      </SummaryContainer>

      <ChartCard>
        <ChartHeader>Visits & Pageviews</ChartHeader>
        <ChartBody>
          <VisitsChart
            groupedPageviews={data.groupedAllPageviews}
            groupedVisitors={data.groupedAllVisitors}
          />
        </ChartBody>
      </ChartCard>

      <ChartCard>
        <ChartHeader>Commisions USD</ChartHeader>
        <ChartBody>
          <SalesChart groupedCommisions={data.groupedAllCommisions} />
        </ChartBody>
      </ChartCard>
    </ViewLayout>
  );
};

const SummaryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StatCard = styled.div`
  flex: 0 0 33%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  :hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  text-align: center;
`;

const ChartCard = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  :hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  text-align: center;
`;

const ChartHeader = styled.div`
  width: 100%;
  padding: 10px;
  background: #fafafb;
  border-bottom: 1px solid #e8e8e8;
  font-size: 20px;
  font-weight: 800;
  text-align: left;
`;

const ChartBody = styled.div`
  width: 100%;
  height: 300px;
`;

const StatNumber = styled.div`
  flex: 0 0 100%;
  font-size: 30px;
  font-weight: 800;
`;

const StatDescription = styled.div`
  flex: 0 0 100%;
  font-size: 14px;
`;

export default AdminDashboardPage;
