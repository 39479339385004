import styled from "styled-components";
import { css } from "styled-components";

const FormHelperText = styled.span`
  color: 444444;
  margin-top: 2px;

  ${props =>
    props.error &&
    css`
      color: red;
    `}
`;

export default FormHelperText;
