import React from "react";
import gql from "graphql-tag";
import ViewLayout from "../../layouts/ViewLayout";
import GQLDataTable from "../../components/GQLDataTable";
import { getDateFromStamp } from "../../lib/util";
import Button from "../../components/UI/Button";
import { navigate } from "@reach/router";
import { useApolloClient, useMutation } from "react-apollo-hooks";
import { useDispatch } from "react-redux";
import { updateToken } from "../../reducers/user";
import { AdminUserExportButton } from "../../components/CSVExportButtons/AdminUserExportButton";

const AdminUsersPage = () => {
  const client = useApolloClient();
  const [impersonateUser] = useMutation(IMPERSONATE_USER);
  const dispatch = useDispatch();

  const tableHeader = [
    { label: "Id", selector: "id", sortable: true },
    { label: "Email", selector: "email", sortable: true },
    { label: "Role", selector: "role", sortable: true },
    { label: "First Name", selector: "firstName" },
    { label: "Last Name", selector: "lastName" },
    { label: "Status", selector: "status" },
    { label: "Created At", selector: "createdAt", sortable: true },
    { label: "Edit" },
    { label: "Impersonate" },
  ];

  const handleEditUser = (userId) => {
    navigate(`/admin/user/${userId}`);
    console.log("Edit user", userId);
  };

  const handleImpersonate = async (userId) => {
    try {
      console.log("Impersonate user", userId);
      const { data } = await impersonateUser({
        variables: {
          userId: Number(userId),
        },
      });

      navigate("/");
      client.resetStore();
      dispatch(updateToken(data.impersonate.token));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ViewLayout title="Users" ActionsComponent={AdminUserExportButton}>
      <GQLDataTable
        query={GET_USERS}
        tableHeader={tableHeader}
        rowsDataSelector={(data) => data.users.nodes}
        totalLengthSelector={(data) => data.users.countBeforeFilter}
        defaultSortCol="createdAt"
        defaultSortDir="desc"
      >
        {(rowsData) =>
          rowsData.map((row, i) => (
            <tr key={i}>
              <td>{row.id}</td>
              <td>{row.email}</td>
              <td>{row.role}</td>
              <td>{row.firstName}</td>
              <td>{row.lastName}</td>
              <td>{row.status}</td>
              <td>{getDateFromStamp(row.createdAt)}</td>
              <td>
                <Button onClick={() => handleEditUser(row.id)}> Edit </Button>
              </td>
              <td>
                <Button onClick={() => handleImpersonate(row.id)}>
                  Impersonate
                </Button>
              </td>
            </tr>
          ))
        }
      </GQLDataTable>
    </ViewLayout>
  );
};

const GET_USERS = gql`
  query GET_USERS($filter: UserFilter) {
    users(filter: $filter) {
      countAfterFilter
      countBeforeFilter
      nodes {
        id
        email
        role
        firstName
        lastName
        status
        createdAt
      }
    }
  }
`;

const IMPERSONATE_USER = gql`
  mutation impersonate($userId: Int!) {
    impersonate(input: { userId: $userId }) {
      token
    }
  }
`;

export default AdminUsersPage;
