import React from "react";

const TrimmedTd = ({ max, children }) => {
  if (!children) return <td></td>;
  if (!children.length) return <td></td>;

  const isTrimemd = children.length > max;

  return (
    <td title={children}>
      {children.substring(0, max)}
      {isTrimemd && <>...</>}
    </td>
  );
};

export default TrimmedTd;
