// Actions
const UPDATE_TOKEN = "user/UPDATE_TOKEN";
const REMOVE_TOKEN = "notification/REMOVE_TOKEN";

// Initial State
const INITIAL_STATE = {
  token: localStorage.getItem("token")
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload
      };

    case REMOVE_TOKEN:
      return {
        ...state,
        token: false
      };

    default:
      return state;
  }
}

export const updateToken = newToken => {
  localStorage.setItem("token", newToken);
  return {
    type: UPDATE_TOKEN,
    payload: newToken
  };
};

export const removeToken = () => {
  localStorage.removeItem("token");
  return {
    type: REMOVE_TOKEN
  };
};
