import React from "react";
import { Chart } from "frappe-charts/dist/frappe-charts.min.esm";

const VisitsChart = ({ groupedVisitors }) => {
  React.useEffect(() => {
    const data = {
      labels: groupedVisitors.map((record) => record.date),
      datasets: [
        {
          name: "Visits",
          values: groupedVisitors.map((record) => record.total),
        },
      ],
    };
    new Chart("#visitsChart", {
      data: data,
      type: "bar", // or 'bar', 'line', 'scatter', 'pie', 'percentage'
      height: 250,
      colors: ["#001426", "#5432ed"],
      barOptions: {
        spaceRatio: 0.2,
      },
      valuesOverPoints: 1, // default: 0
    });
  }, [groupedVisitors]);

  return <div id="visitsChart" />;
};

export default VisitsChart;
