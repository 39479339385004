// Actions
const SET_LINK_MODAL = "common/SET_LINK_MODAL";

// Initial State
const INITIAL_STATE = {
  linkModalOpen: false
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_LINK_MODAL:
      return {
        ...state,
        linkModalOpen: action.payload
      };

    default:
      return state;
  }
}

export const openLinkModal = () => {
  return {
    type: SET_LINK_MODAL,
    payload: true
  };
};

export const closeLinkModal = () => {
  return {
    type: SET_LINK_MODAL,
    payload: false
  };
};
