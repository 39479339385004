export const getDateFromStamp = (timestamp) =>
  new Date(Number(timestamp)).toDateString();

export const removeNullProps = (object) => {
  const temp = { ...object };
  Object.keys(temp).forEach((tempKey) => {
    if (temp[tempKey] === null) delete temp[tempKey];
  });
  return temp;
};

export const downloadCSV = (csv, filename) => {
  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  var link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};
