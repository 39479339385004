import React from "react";
import Button from "../UI/Button";
import { apolloClient } from "../..";
import { json2csv } from "json-2-csv";
import Loader from "../Loader";
import { gql } from "apollo-boost";
import { downloadCSV } from "../../lib/util";

export const SalesExportButton = () => {
  const [isExporting, setIsExporting] = React.useState(false);

  const handleUserExport = async () => {
    try {
      setIsExporting(true);
      await processAndExportData();
    } catch (error) {
      console.error(error);
    } finally {
      setIsExporting(false);
    }
  };

  if (isExporting) return <Loader />;

  return <Button onClick={handleUserExport}> Export Sales </Button>;
};

const processAndExportData = async () => {
  try {
    const { data } = await apolloClient.query({
      query: GET_SALES,
      variables: {
        filter: {
          limit: 100000,
          orderByDesc: "id",
        },
      },
    });

    console.log(data);

    //Export the to csv file that can be downloaded
    const csvParser = (err, csv) => {
      if (err) {
        console.error(err);
        throw err;
      }
      console.log(csv);
      downloadCSV(csv, "sales.csv");
    };

    json2csv(data.me.sales.nodes, csvParser, {
      keys: [
        "id",
        "orderRef",
        "amount",
        "commisionAmount",
        "status",
        "statusReason",
        "payment.id",
        "visitor.id",
        "visitor.referrerUrl",
        "visitor.destinationUrl",
        "createdAt",
      ],
    });
  } catch (error) {
    console.error(error);
  }
};

const GET_SALES = gql`
  query GET_SALES($filter: SaleFilter) {
    me {
      id
      sales(filter: $filter) {
        countBeforeFilter
        countAfterFilter
        nodes {
          id
          orderRef
          amount
          commisionAmount
          status
          statusReason
          payment {
            id
          }
          visitor {
            id
            referrerUrl
            destinationUrl
          }
          createdAt
        }
      }
    }
  }
`;
